import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations
} from '@angular/platform-browser/animations';
import {
  provideRouter,
  TitleStrategy,
  withComponentInputBinding,
  withInMemoryScrolling
} from '@angular/router';
import {
  HttpCacheInterceptor,
  HttpErrorInterceptor,
  HttpTokenInterceptor
} from '@interceptors/http.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { appRoutes } from '@routes/app.routes';
import { PageTitleStrategy } from '@services/page-title-strategy.service';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';

const modules = [
  BrowserModule,
  BrowserAnimationsModule,
  MatSnackBarModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient]
    }
  })
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    importProvidersFrom(...modules),
    provideHttpClient(
      // withFetch(),
      withInterceptors([
        HttpTokenInterceptor,
        HttpErrorInterceptor,
        HttpCacheInterceptor
      ])
    ),
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      }),
      withComponentInputBinding()
    ),
    provideShareButtonsOptions(shareIcons()),
    { provide: 'STORAGE', useFactory: getStorage },
    { provide: TitleStrategy, useClass: PageTitleStrategy }
  ]
};

export function getStorage() {
  return typeof window !== 'undefined' ? window.localStorage : null;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
